















































































import Vue from "vue";
export default Vue.extend({
  data: function() {
    return {
      newsLetter: true,
      name: "",
      email: "",
      lastName: "",
      nameIsInvalid: false,
      emailIsInvalid: false,
      lastNameIsInvalid: false,
    };
  },
  methods: {
    ValidateForm: function() {
      if (this.name.length < 1 || this.name == " ") {
        this.nameIsInvalid = true;
      } else this.nameIsInvalid = false;

      if (this.lastName.length < 1 || this.lastName == " ") {
        this.lastNameIsInvalid = true;
      } else this.lastNameIsInvalid = false;


      if (this.email.length < 1 || this.email.indexOf("@") < 0) {
        this.emailIsInvalid = true;
      } else this.emailIsInvalid = false;

      if (this.emailIsInvalid || this.nameIsInvalid || this.lastNameIsInvalid) {
        return;
      }
      this.UpdateUser();
    },
    UpdateUser: function() {
      this.$store.dispatch("UpdateUser", {
        name: this.name,
        email: this.email,
        lastName: this.lastName,
        newsletter: this.newsLetter,
      });
      this.$router.push("/2");
    },
    HandleNewsLetter: function() {
      const checkbox = this.$refs.checkbox as HTMLInputElement;
      checkbox.checked = !checkbox.checked;

      if (checkbox.checked) {
        this.newsLetter = true;
      } else this.newsLetter = false;
    },
  },
  mounted: function() {
    this.$store.dispatch("SetLogoStyle", { color: "light" });
  },
});
